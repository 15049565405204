<template>
  <c-flex
    border="1px solid #F2F2F2"
    box-shadow="0px 5px 30px rgba(0, 0, 0, 0.05)"
    border-radius="md"
    mt="10px"
    :p="['20px', '28px']"
    :w="['100%', '739px']"
  >
    <c-image
      w="140px"
      h="140px"
      border-radius="md"
      :src="getPhotoProgram()"
      :alt="title"
      :display="['none', 'block']"
    />
    <c-box :pl="['0', '24px']" w="100%">
      <c-flex border-bottom="1px solid #f4f4f4" align-items="center" pb="20px">
        <c-flex>
          <c-flex flex-direction="row" align-items="flex-start">
            <c-box
              w="48px"
              h="48px"
              borderRadius="6px"
              overFlow="hidden"
              marginRight="20px"
              :display="['block', 'none']"
            >
              <c-image
                w="48px"
                h="48px"
                borderRadius="6"
                :src="getPhotoProgram()"
                :alt="title"
              />
            </c-box>
            <c-box :display="['block', 'flex']">
              <c-text
                font-weight="bold"
                font-size="14px"
                line-height="150%"
                color="#111111"
                mr="5px"
                pt="0"
                :display="['block', 'inline-block']"
              >
                {{ title }}
              </c-text>
              <c-text font-size="14px" line-height="150%" color="#111111">
                ({{ level }})
              </c-text>
            </c-box>
          </c-flex>
        </c-flex>
        <!-- <c-image
          :src="require('@/assets/icon-chevron-right-filled.svg')"
          alt="icon arrow right"
          w="18px"
          h="18px"
      /> -->
      </c-flex>
      <table v-chakra my="20px" :w="['100%', 'inherit']">
        <tr v-chakra h="25px">
          <td v-chakra font-size="12px" color="brand.900">Invoice</td>
          <td
            v-chakra
            :max-width="['inherit', '100px']"
            font-weight="500"
            font-size="12px"
            color="#333"
            white-space="nowrap"
            text-overflow="ellipsis"
            overflow="hidden"
          >
            {{ invoiceId }}
          </td>
        </tr>
        <tr v-chakra h="25px">
          <td v-chakra font-size="12px" color="brand.900">Durasi</td>
          <td v-chakra font-weight="500" font-size="12px" color="#333">
            {{ duration }}
          </td>
        </tr>
        <tr v-chakra h="25px">
          <td v-chakra font-size="12px" color="brand.900">Status Pembayaran</td>
          <td v-chakra font-weight="500" font-size="12px" color="#333">
            {{ status }}
          </td>
        </tr>
        <tr v-chakra h="25px">
          <td v-chakra font-size="12px" color="brand.900">Harga</td>
          <td v-chakra font-weight="500" font-size="12px" color="#333">
            Rp{{ Number(price).toLocaleString("id") }}
          </td>
        </tr>
      </table>
    </c-box>
  </c-flex>
</template>

<script>
import generalMixin from "@/utils/general-mixins";

export default {
  name: "program-card",
  props: ["title", "level", "duration", "status", "invoiceId", "price"],
  mixins: [generalMixin],
};
</script>

<style scoped>
</style>
