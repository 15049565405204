<template>
  <c-box flexGrow="1">
    <c-flex
      flex-direction="column"
      :mx="[null, '20px']"
      :mt="[null, '25px']"
      :mb="['80px', null]"
    >
      <h1 v-chakra font-weight="bold" font-size="18px" color="#111">
        Riwayat Pembelian
      </h1>

      <c-text font-weight="500" font-size="12px" color="#333" mt="25px">
        Program yang pernah dibeli
      </c-text>
      <hr v-chakra mt="7px" />

      <EmptyCard v-if="isEmpty" label="Riwayat Pembelian tidak tersedia" />

      <c-box v-if="!isEmpty">
        <PembelianCard
          v-for="item in pembelian"
          :key="item.id"
          :title="item.productName"
          :level="item.programService"
          :ahli-gizi="item.ahliGizi"
          :duration="item.duration"
          :status="item.status"
          :invoice-id="item.invoiceNumber"
          :price="item.total"
        />
      </c-box>
    </c-flex>
  </c-box>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PembelianCard from "./pembelian-card.vue";
import EmptyCard from "./empty.vue";

export default {
  name: "ProfilePembelianPage",
  components: {
    PembelianCard,
    EmptyCard,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      pembelian: "profilePembelian/purchaseHistory",
    }),
    isEmpty() {
      if (this.pembelian.length === 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      listPurchaseHistory: "profilePembelian/listPurchaseHistory",
    }),
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.listPurchaseHistory();
      },
    },
  },
  mounted() {
    if (!this.isAuthenticated) return this.$router.push({ name: "auth.login" });
  },
};
</script>
